<template>
  <!-- TODO: Replace headerButtons with a button bar component that takes md-buttons as a child (maybe in the future when md-components is a considered project?) -->
  <md-report-layout :header-buttons="headerButtons"
                    :loading="loading"
                    :loading-text="loadingText"
                    ref="reportLayout">

    <template v-slot:header-left>
      <md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged" display-mode="header"/>
    </template>

    <template v-slot:employee-list>
      <!--md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged"/-->

      <md-employee-list :loading="loadingEmployees"
                        :employees="employees"
                        :can-select-multiple="true"
                        :init-all-selected="true"
                        @employeeSelected="onEmployeeSelected"
                        style="height: calc(100vh - 400px); margin-bottom: 10px;"/>
    </template>

    <template v-slot:report-settings>
      <md-report-config :options="reportConfigData" @configChanged="onReportConfigChange"/>
    </template>

    <template v-slot:report-list>
      <ml-report-list :reports="reports"
                      :loading="loadingReports"
                      @deleteReport="openDeleteReportPopup"
                      @downloadReport="downloadReport"
                      @reportClicked="openReport"/>
    </template>

    <template v-slot:button-bar v-if="creatingReport || !canCreateReport">
      <span v-if="creatingReport">
        <i class="fas fa-circle-notch fa-spin"></i>
        Report wird erstellt
      </span>
      <span v-else>
        Bitte mindestens eine Art der Abwesenheiten inkludieren
      </span>
    </template>

  </md-report-layout>
</template>

<script>
import moment from 'moment';
import MdReportLayout from '@/components/reportcontext/ReportLayout';
import MdDateSelect from '@/components/uicomponents/md-date-select';
import MlReportList from '@/components/uicomponents/ml-report-list';
import { reportMixin } from '@/components/reportcontext/report-mixin';
import { mapGetters } from "vuex";
import VueEventBus from '../../vue-event-bus';

export default {
  name: 'ReportEmployeeCrossLocation',
  components: {
    MdReportLayout,
    MdDateSelect,
    MlReportList,
  },
  mixins: [ reportMixin ],
  data() {
    return {
      loadingText: 'Report Mitarbeiter Einsatzübersicht wird geladen',
    }
  },
  created: function () {
    console.log("CREATED");
    VueEventBus.$on("JobReportEvent:jobCreated", this.jobCreated);
    VueEventBus.$on("JobReportEvent:jobStarted", this.jobStarted);
    VueEventBus.$on("JobReportEvent:jobEnded", this.jobEnded);
    VueEventBus.$on("JobReportEvent:jobFailed", this.jobFailed);
  },
  beforeDestroy: function () {
    // console.log("BEFORE DESTROY");
    VueEventBus.$off("JobReportEvent:jobCreated", this.jobCreated);
    VueEventBus.$off("JobReportEvent:jobStarted", this.jobStarted);
    VueEventBus.$off("JobReportEvent:jobEnded", this.jobEnded);
    VueEventBus.$off("JobReportEvent:jobFailed", this.jobFailed);
  },
  destroyed: function () {
    // console.log("DESTROYED");
  },
  mounted() {
    if (this.$store.state.jwt !== undefined && this.$store.state.companyId !== undefined) {

      this.refresh();

      // NOTE:
      // demo code for updateReportConfig
      // this.updateReportConfig('test', 1);
      // this.updateReportConfig('testObj', {});
      // this.updateReportConfig(['testObj', 'key'], 1);
      // creates a new deeply nested config entry
      // this.updateReportConfig(['testObj', 'key2', 'newNested', 'newNested2'], 1);
    }
  },
  computed: {
    ...mapGetters(['companyId','companySettings', 'storesSettings']),
    headerButtons() {
      let helperLinks = [];
      let buttons = [
        {
          classes: 'add-employeetype',
          type: 'block gray',
          disabled: false,
          icon: 'fa-th-list',
          click: ($event) => {
          },
          show: true,
          tooltip: 'Mitarbeiter-Einsatzübersicht',
          title: 'Mitarbeiter-Einsatzübersicht',
          helperText: 'Die Mitarbeiter-Einsatzübersicht zeigt Standortübergreifende Aushilfen in einem Bericht an.',
          //externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/1134428161"
        },
        {
          type: 'block green',
          disabled: this.creatingReport || !this.canCreateReport,
          icon: this.creatingReport ? 'fa-redo' : 'fa-file-check',
          click: ($event) => {
            this.createReportProxy();
          },
          show: true,
          tooltip: 'Report erstellen',
          title: 'Report erstellen',
        },
      ];
      let state = {
        buttons: buttons,
        helperLinks: helperLinks,
        showListIconAlways: true,
      };

      state.helperLinks = [
        {
            icon: "fa-video",
            title: "Schulungsvideo ansehen",
            helperText: "Sieh dir das Video der Basisschulung zu diesem Bereich an um nochmal die Erklärung zu allen grundlegenden Funktionen zu sehen.",
            externalUrl: "https://www.youtube.com/watch_popup?v=BePh4xNJvsY",
            externalLabel: "Basisschulung 'Reports'",
            cancelLabel: "Schließen"
        },
        {
          title: 'Support erhalten',
          helperText: 'Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf \'Support kontaktieren\', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf \'Senden\'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.',
          externalUrl: 'https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/2/create/15',
          externalLabel: 'Support kontaktieren',
          cancelLabel: 'Schließen',
        },
      ];
      return state;
    },

    canCreateReport() {
      // const fileContent = this.reportConfig.fileContent || {};
      return true; //(fileContent.selectedTypes || []).length > 0;
    },
  },
  watch: {
    companyId(newVal, oldVal) {
      console.log("COMPANY ID CHANGED", newVal, oldVal);
      this.refresh(true);
    },
    rangeStart() {
      this.updateReportConfig('reportname', this.getReportName());
    },
  },
  // methods that implement data (BUSINESS) logic. (https://en.wikipedia.org/wiki/Business_logic)
  methods: {
    getReportName() {
      return this.rangeMode === 'monthlyRange'
          ? `Mitarbeiter-Einsatzübersicht, ${ moment(this.rangeStart).format('MMMM YYYY') }`
          : `Mitarbeiter-Einsatzübersicht, ${ moment(this.rangeStart).format('DD.MM.YYYY') } bis ${ moment(this.rangeEnd).format('DD.MM.YYYY') }`;
    },

    async refresh(force = false) {
      this.loading = true;
      this.updateReportConfig('reportname', this.getReportName());

      try {
        await Promise.all([
          this.$helpers.GetCompanySettings(force),
          this.$helpers.GetStoresSettings(force),
          this.$helpers.GetExportSettingsMap(force),
          this.loadReports('EMPLOYEECROSSLOCATION'),
          this.loadEmployees(true),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.generateReportConfigOpts();

        // console.log(this.getReportConfigValue('reportname'));
        // reportConfig can be loaded after generating the options, as reportConfig does not overwrite the options
        this.reportConfig = this.loadReportConfig();
        this.updateReportConfig('reportname', this.getReportName());

        console.log(this.reports);

        this.loading = false;
      }
    },

    onEmployeeSelected(employee) {
      console.log(employee);
      employee.isSelected = true;
      this.$helpers.info("Achtung!", "Aktuell können keine Mitarbeiter abgewählt werden.");
      // this.selectedEmployee = employee.isSelected ? employee : null;
      // No need to update employeeList as the currently selected employee is stored in its own variable
      // this.employeeList = this.employeeList.map(emp => ({ ...emp, selected: emp.id === employee.id }));
    },

    generateReportConfigOpts() {
      let disabled = !this.CheckEnableConfigEdit
      let specialDisabled = !this.CheckEnableConfigSpecialEdit
      const nameSection = this.createReportConfigSection('Dokumentname', true);
      this.addTextConfigOption(nameSection, 'reportname', 'Dokumentname eingeben', this.getReportName(), true);

      const commentSection = this.createReportConfigSection('Kommentar');
      this.addTextConfigOption(commentSection, 'reportcomment', 'Kommentar eingeben');


      const formatSection = this.createReportConfigSection('Dateiformat', false, disabled);
      this.addSelectConfigOption(formatSection, 'fileformat', [
        this.addSelectableValue('PDF', 'pdf', true),
        this.addSelectableValue('CSV', 'csv'),
      ]);


      const storeSection = this.createReportConfigSection('Standorte', true, disabled);
      this.addSelectConfigOption(storeSection, 'storeIds', [
        ...this.storesSettings.data.map(store => this.addSelectableValue(store.name, store.id))
      ], true);

      const groupingSection = this.createReportConfigSection('Gruppierung', false, disabled);
      this.addSelectConfigOption(groupingSection, 'groupingParameter', [
        this.addSelectableValue('Gruppiert nach Zielstandort', 'location', true),
        this.addSelectableValue('Gruppiert nach Mitarbeiter', 'employee'),
      ]);


      // const contentSection = this.createReportConfigSection('Dokumentinhalt', false, disabled);
      // this.addSelectConfigOption(contentSection, 'fileContent', [
      //   this.addSelectableValue('Abwesenheiten inkludieren', [
      //     this.addSelectableValue('Urlaub', 1),
      //     this.addSelectableValue('Krankenstand', 2),
      //     this.addSelectableValue('Feiertagsausgleich', 3),
      //     this.addSelectableValue('Pflegeurlaub', 6),
      //     this.addSelectableValue('Fortbildung', 7),
      //     this.addSelectableValue('Lohnfortzahlung', 8),
      //     this.addSelectableValue('Sonstige Abwesenheiten', 4),
      //   ], [ 1, 2, 3, 6, 7, 8, 4 ], 'selectedTypes'),
      // ], true);

/*
      const hourFormatSection = this.createReportConfigSection('Stundendarstellung', false, disabled);
      this.addSelectConfigOption(hourFormatSection, 'settingHourFormat', [
        this.addSelectableValue('Stunden & Minuten', 'Hm', true),
        this.addSelectableValue('3 Kommastellen', '3'),
        this.addSelectableValue('2 Kommastellen', '2'),
      ]);*/

      /*
      const documentFormatSection = this.createReportConfigSection('Dokumentformatierung', false, disabled);
      this.addSelectConfigOption(documentFormatSection, 'settingEmployeeFormat', [
        this.addSelectableValue('Seitenumbruch nach jedem Mitarbeiter', 'one', true),
        this.addSelectableValue('Mehrere Mitarbeiter pro Seite', 'dynamic'),
      ]);
      this.addSelectConfigOption(documentFormatSection, 'settingSignatureLine', [
        this.addSelectableValue('Unterschrift-Feld anzeigen', true, true),
        this.addSelectableValue('Unterschrift-Feld verbergen', false),
      ]);
      this.addSelectConfigOption(documentFormatSection, 'settingColorFormat', [
        this.addSelectableValue('Farbe', 'color', true),
        this.addSelectableValue('Schwarz-Weiß', 'blackwhite'),
      ]);
      this.addSelectConfigOption(documentFormatSection, 'settingFontSize', [
        this.addSelectableValue('Textgröße – Standard', 'default', true),
        this.addSelectableValue('Textgröße – Groß', 'big'),
      ]);*/

      const headerFormatSection = this.createReportConfigSection('Kopfzeile', false, specialDisabled);
      this.addSelectConfigOption(headerFormatSection, 'settingDateFormat', [
        this.addSelectableValue('DD.MM.YYYY', 'DD.MM.YYYY', true),
        this.addSelectableValue('YYYY-MM-DD', 'YYYY-MM-DD'),
      ]);
      this.addSelectConfigOption(headerFormatSection, 'headerShowQr', [
        this.addSelectableValue('QR Code anzeigen', true, true),
        this.addSelectableValue('QR Code verbergen', false),
      ]);

    },

    async createReportProxy() {
      if (this.rangeMode === 'customRange') {

        if (this.rangeStart.month() != this.rangeEnd.month() || this.rangeStart.year() != this.rangeEnd.year()) {
          this.$helpers.error('Fehler', 'Start und Enddatum müssen im selben Monat liegen!');
          return;
        }
        if (this.rangeStart > this.rangeEnd) {
          this.$helpers.error('Fehler', 'Startdatum muss vor Enddatum liegen!');
          return;
        }

      }
      await this.generateReport();
    },

    async generateReport() {
      this.creatingReport = true;

      const employeeIds = this.selectedEmployeeIds;

      const dateFormats = {
        'DD.MM.YYYY': 'dmY',
        'YYYY-MM-DD': 'Ymd',
      };
      const dateFormat = dateFormats[this.reportConfig.settingDateFormat] || 'dmY';

      const hourFormats = {
        '3': '3',
        '2': '2',
        'Hm': 'Hm',
      };
      const hourFormat = hourFormats[this.reportConfig.settingHourFormat] || 'Hm';

      const name = this.reportConfig.reportname;
      const comment = this.reportConfig.reportcomment;
      const config = {
        'year': this.rangeStart.year(),
        'month': this.rangeStart.month() + 1,
        'headerDateFormat': dateFormat,
        'hourFormat': hourFormat,
        'pageFormat': this.reportConfig.settingEmployeeFormat,
        'colorFormat': this.reportConfig.settingColorFormat,
        'fontSize': this.reportConfig.settingFontSize,
        'showSignatureLine': this.reportConfig.settingSignatureLine,
        'storeIds': this.reportConfig.storeIds,
        'groupingParameter': this.reportConfig.groupingParameter,
        // 'selectedTypes': this.reportConfig.fileContent.selectedTypes,
        'headerShowQr': this.reportConfig.headerShowQr,
        'documentComment': comment,
        'headerLogo': 'ml',
        'fileformat': this.reportConfig.fileformat ?? 'pdf'
      };

      if (this.rangeMode === 'customRange') {


        config.fromDate = this.rangeStart.format();
        config.toDate = this.rangeEnd.format();

      }

      await this.createReport('EMPLOYEECROSSLOCATION', name, comment ? comment : "", config);
    },

    jobCreated: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB CREATED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 50);
    },
    jobStarted: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB STARTED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 100);
    },
    jobEnded: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB ENDED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    jobFailed: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB FAILED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    reloadDocuments: function () {
      this.loadReports('EMPLOYEECROSSLOCATION');
    }

  },
}
</script>

<style scoped>
</style>
